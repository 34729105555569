@import '../../components/glassTheme/Common.scss';

.pairings-content {
  margin:0;
  box-sizing:border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 20px;
  padding-bottom: 100px;
}

.matchesControls {
  width: 100%;
}

.matches {
  width: 100%;
  height: 100%;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.match-card {
  width: 90%;
  height: 100%;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.match {
  width: 100%;
  height: 100px;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  z-index: 10;
}

.searchError {
  @extend .raised-glass-bubble;
  width: 60%;
  height: 100px;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 10;
}

.searchErrorIcon {
  @extend .glass-element-subtle;
  width: 30px;
  height: 30px;
}

.favourite-icon {
  color: white;
  width: 20px;
  height: 20px;
  margin: 2px;
}

.filterControls {
  position: fixed;
  bottom: 50px;
  right: 50px;
  display: flex;
  flex-direction: row;
  z-index: 100;
  gap: 10px;
}