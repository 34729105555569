html {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  overflow: auto;
  background-color: #5233bd;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
}
