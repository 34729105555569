.glass-number-input {
    box-shadow: rgba(0, 0, 0, 0.15) 1px 2px 4px 2px inset;
    background: rgba(200, 200, 200, 0.2);
    font-size: small;
    text-align: center;
    color: white;
    border: none;
    border-radius: 5px;
    width: 46px;
    height: 25px;
    margin-right: .5rem;
}

.glass-number-input[type="number"]::-webkit-inner-spin-button {
    display: none;
}