@import '../../components/glassTheme/Common.scss';

.autoUploadControlPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.autoUploadControls {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding: 10px, 10px;
}

.fileSelectControls {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 30px, 10px;
}

.timerControls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px, 10px;
}

.timerControlsDataColumns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5px;
}

.timerControlsData {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
  width: 100%;
  gap: 5px;
}

.fileSelectButton {
  @extend .glass-button-icon;
  width: 150px;
  margin: 5px;
}

.syncIcon {
  width: 40px;
  height: 40px;
  margin: 5px;
  color: white;
  opacity: 0.5;
  animation: spin 5s infinite linear;
  margin-bottom: 20px;
  animation-fill-mode: both;
  animation-play-state: running;
}

.autoUploadControls .syncIcon {
  animation-play-state: running;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}