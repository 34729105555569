@import '../../components/glassTheme/Common.scss';

.stream-data-controls-card {
  @extend .cardWrapper;
  width: 80%;
  max-width: 80%;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.stream-data-controls {
  width: 100%;
  min-height: 120px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  gap: 10px;
}

.streamDataControlsTooltipIcon {
  color: rgb(255, 255, 255, 0.8);
  position: absolute;
  top: 20px;
  right: 25px;
  z-index: 30;
  height: 25px;
  width: 25px;
  transition: 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.streamDataControlsTooltipIcon:hover {
  transform: scale(1.3);
}
.streamDataControlsTooltipHidden {
  @extend .darkest-glass;
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 30px;
  width: 0%;
  height: 0%;
  top: 5px;
  right: 5px;
  border-radius: 38px;
  justify-content: center;
  align-items: center;
  z-index: 20;
  opacity: 0;
  overflow: scroll;
}
.streamDataControlsTooltip {
  @extend .streamDataControlsTooltipHidden;
  opacity: 1;
  width: 400px;
  height: 350px;
  transition: opacity 0.3s ease-in-out;
}
.streamDataControlsTooltipContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 80%;
}
.streamDataControlsTooltipContentIcon {
  color: rgb(255, 255, 255, 0.8);
  height: 25px;
  width: 25px;
}
.streamDataControlsTooltipContentDefinitionPair {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.streamDataControlsTooltipContentTitle {
  @extend .glass-title;
  font-size: small;
  font-weight: 300;
  text-align: left;
}
.streamDataControlsTooltipContentDefinition {
  @extend .glass-body;
  font-size: small;
  font-weight: 300;
  text-align: left;
}
.matchEditorControls {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.matchEditorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 100%;
}

.matchEditor {
  @extend .recessed-glass-component;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  height: 80px;
  border-radius: 10px;
  width: 100%;
  overflow: scroll;
  position: relative;
}

.matchEditorReadOnly {
  @extend .matchEditor;
}


.matchEditorOverlayIcon {
  color: rgb(255, 255, 255, 0.8);
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 10;
}

.matchCardOverlayIcon {
  color: rgb(255, 255, 255, 0.6);
  position: absolute;
  top: 7px;
  left: 8px;
  z-index: 10;
  height: 16px;
  width: 16px;
}
.matchCardOverlayIconRight {
  @extend .matchCardOverlayIcon;
  left: auto;
  right: 8px;
}

.matchEditorRail {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  height: 100%;
  gap: 10px;
  padding: 2px;
}
.matchEditor::-webkit-scrollbar {
  display:none
}

.matchEditorRailDivider {
  @extend .dark-glass;
  opacity: 0.4;
  min-width: 2px;
  margin: 2px;
  height: 100%;
}

.unsavedChangesIndicator {
  color: rgba(255, 255, 255, 0.8);
  height: 40px;
}

.flexBookendLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 50%;
}
.flexBookendRight {
  @extend .flexBookendLeft;
  justify-content: flex-end;
  text-align: right;
}

.matchEditorHeader {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  padding: 5px 10px;
  gap: 10px;
}

.matchEditorFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  gap: 10px;
}

.railOrderControls {
  @extend .recessed-glass-component;
  border-radius: 200px;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: center;
  gap: 20px;
}

.matchCard {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100px;
  margin: 2px;
  border-radius: 10px;
  gap: 2px;
  padding: 5px;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  position: relative;
}
.matchCardSelectable {
  @extend .matchCard;
}
.matchCardSelectable:hover {
  background-color: rgba(0, 0, 0, 0.75);
  transform: scale(1.1);
}
.matchCardSelectableSelected {
  @extend .matchCardSelectable;
  background-color: rgba(0, 0, 0, 0.8);
  outline: 2px solid rgba(255, 255, 255, 0.8);
}

.matchCardPlaceholder {
  @extend .matchCard;
  background-color: rgba(0, 0, 0, 0.1);
}
.matchCardLoading {
  @extend .matchCard;
  background-color: rgba(0, 0, 0, 0.0);
}

.matchCardTitle {
  @extend .glass-body;
  font-size: large;
  font-weight: 600;
  text-align: center;
}
.matchCardSubtitle {
  @extend .glass-body;
  font-size: small;
  font-weight: 300;
  text-align: center;
}

.matchEditorSubtitleIcon {
  align-self: center;
  padding-right: 2px;
}

.stream-data-header {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  width: 100%;
}

.channel-select-controls {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 0px;
  height: 70px;
  width: 200px;
  padding: 0px 0px 0px 30px;
}

.channel-select-select {
  @extend .glass-select;
  width: 100px;
}

.channel-select-subtitle {
  @extend .glass-body;
  text-align: left;
  padding: 5px;
}

.footerButton {
  @extend .glass-button-icon;
  font-size: small;
  padding: 0 15px;
  align-self: center;
}

.matchEditorTitle {
  @extend .glass-h2;
  height: 100%;
}
.matchEditorSubtitle {
  @extend .glass-body;
  font-weight: 100;
}