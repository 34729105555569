
@import '../../components/glassTheme/Common.scss';

.toggle-button {
    @extend .dark-glass;
    display: flex;
    flex-direction: row;
    color: #fff;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toggle-icon {
    width: 15px;
    height: 15px;
    fill: gray;
}

.toggle-icon-selected {
    @extend .toggle-icon;
    fill: white;
}
.toggle-icon-deselected {
    fill: gray;
}

.toggle-button:hover .toggle-icon-selected {
    fill: white;
}
.toggle-button:hover .toggle-icon-deselected  {
    fill: lightgray;
}

.toggle-button:hover {
    @extend .glass;
    scale: 1.1;
}

.toggle-button-selected {
    @extend .glass;
}

.toggle-button-label-container {
    @extend .darkest-glass;
    display: flex;
    flex-direction: row;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100px;
    border-radius: 5px;
    padding: 5px;
    opacity: 0;
    pointer-events: none;
}

.toggle-button-label-container-top {
    @extend .toggle-button-label-container;
    bottom: 40px;
    left: 50%;
    width: 100px;
    transform: translateX(-50%);
}

.toggle-button-label-container-bottom {
    @extend .toggle-button-label-container;
    bottom: -40px;
    left: 50%;
    width: 100px;
    transform: translateX(-50%);
}

.toggle-button-label-container-left {
    @extend .toggle-button-label-container;
    right: 110%;
    top: 50%;
    width: 100px;
    transform: translateY(-50%);
}

.toggle-button-label-container-right {
    @extend .toggle-button-label-container;
    left: 110%;
    top: 50%;
    width: 100px;
    transform: translateY(-50%);
}

.toggle-button-label {
    @extend .glass-caption;
    text-align: center;
    overflow: visible;
}

.toggle-button:hover .toggle-button-label-container {
    opacity: 1;
    transition: opacity 0s 1s ease-in;
}