
@import '../../components/glassTheme/Common.scss';

.uploadTrackerBase {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: start;
    width: 100%;
    padding: 7px 30px 0px 7px;
    transition: width height 0.3s ease-in-out;
}
.uploadTrackerDefault {
    @extend .uploadTrackerBase;
}
.uploadTrackerSuccess {
    @extend .uploadTrackerBase;
}
.uploadTrackerWarning {
    @extend .uploadTrackerBase;
}
.uploadTrackerError {
    @extend .uploadTrackerBase;
}
.uploadTrackerUploading {
    @extend .uploadTrackerBase;
}

.uploadTrackerData {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: start;
    justify-content: center;
}

.resultIndicatorBase {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 0.5px solid gray;
}
.resultIndicatorDefault {
    @extend .resultIndicatorBase;
    background-color: $sentiment-neutral;
}
.resultIndicatorSuccess {
    @extend .resultIndicatorBase;
    background-color: $sentiment-positive;
}
.resultIndicatorWarning {
    @extend .resultIndicatorBase;
    background-color: $sentiment-warning;
}
.resultIndicatorError {
    @extend .resultIndicatorBase;
    background-color: $sentiment-negative;
}
.resultIndicatorUploading {
    @extend .resultIndicatorBase;
    background-color: $sentiment-neutral;
}

@keyframes pulsing {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.resultIcon {
    color: white;
    height: 18px;
    width: 18px;
}
.uploadingIcon {
    color: white;
    height: 15px;
    width: 15px;
    animation: pulsing 1s infinite;
}
.resultTitle {
    @extend .glass-h4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.resultSubtitle {
    @extend .glass-caption;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}