
@import '../../components/glassTheme/Common.scss';

.filter-button {
    @extend .dark-glass;
    @extend .diffuse;
    display: flex;
    flex-direction: row;
    color: #fff;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
}

.filter-icon {
    width: 25px;
    height: 25px;
    fill: gray;
}

.filter-label-container {
    @extend .dark-glass;
    @extend .diffuse;
    display: flex;
    flex-direction: row;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: -50px;
    left: 50%;
    width: 200px;
    transform: translateX(-50%);
    border-radius: 50px;
    padding: 10px;
    transition: .3s;
    opacity: 0;
    pointer-events: none;
}

.filter-label {
    @extend .glass-body;
    text-align: center;
    font-size: small;
    overflow: visible;
}

.selected {
    width: 25px;
    height: 25px;
    fill: white;
}
.filter-button:hover .selected {
    fill: white;
}
.filter-button:hover .deselected {
    fill: lightgray;
}
.deselected {
    fill: gray;
}
.filter-button:hover {
    @extend .glass;
    scale: 1.1;
}

.filter-button:hover .filter-label-container {
    opacity: 1;
}