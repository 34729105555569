
@import '../../components/glassTheme/Common.scss';

.iconbutton-button {
    @extend .glass-element-bold;
    @extend .glass-bgelement-thin;
    outline: 1px solid rgba(255, 255, 255, 0.4);
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    align-items: center;
    z-index: 100;
}

.iconbutton-icon {
    width: 12px;
    height: 12px;
    fill: white;
}
.iconbutton-icon-disabled {
    @extend .iconbutton-icon;
    fill: gray;
}

.iconbutton-button:hover .iconbutton-icon-selected {
    fill: white;
}
.iconbutton-button:hover .iconbutton-icon-deselected  {
    fill: lightgray;
}

.iconbutton-button:hover {
    @extend .dark-glass;
    scale: 1.3;
    transition: scale 0.3s;
    outline: 1px solid rgba(100, 100, 100, 0.4);
}

.icon-label-container {
    @extend .darkest-glass;
    display: flex;
    flex-direction: row;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100px;
    border-radius: 5px;
    padding: 5px;
    opacity: 0;
    pointer-events: none;
}

.icon-label-container-below {
    @extend .icon-label-container;
    bottom: -40px;
    left: 50%;
    width: 100px;
    transform: translateX(-50%);
}

.icon-label-container-above {
    @extend .icon-label-container;
    bottom: 40px;
    left: 50%;
    width: 100px;
    transform: translateX(-50%);
}

.icon-label {
    @extend .glass-caption;
    text-align: center;
    overflow: visible;
}

.iconbutton-button:hover .icon-label-container {
    opacity: 1;
    transition: opacity 0.3s 0.7s ease-in;
}