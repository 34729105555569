
@import '../../components/glassTheme/Common.scss';

.hamburger-button {
    // @extend .dark-glass;
    display: flex;
    flex-direction: row;
    color: #fff;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
}

.hamburger-icon {
    width: 30px;
    height: 30px;
}
.hamburger-button:hover .hamburger-icon {
    animation: wobble 1s ease 1;
}

.hamburger-label-container {
    @extend .dark-glass;
    display: flex;
    flex-direction: row;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: -50px;
    left: 50%;
    width: 200px;
    transform: translateX(-50%);
    border-radius: 50px;
    padding: 10px;
    transition: .3s;
    opacity: 0;
    pointer-events: none;
}

.hamburger-label {
    @extend .glass-body;
    text-align: center;
    font-size: small;
    overflow: visible;
}

.selected {
    width: 25px;
    height: 25px;
    fill: white;
}
.hamburger-button:hover .selected {
    fill: black;
}
.hamburger-button:hover .deselected {
    fill: black;
}
.deselected {
    fill: gray;
}
.hamburger-button:hover {
    background-color: white;
    scale: 1.1;
    color: black;
}

.hamburger-button:hover .filter-label-container {
    opacity: 1;
}

@keyframes wobble {
    0%, 100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    15% {
        -webkit-transform: translateX(-4px) rotate(-5deg);
        transform: translateX(-4px) rotate(-5deg);
    }

    30% {
        -webkit-transform: translateX(calc(4px / 2)) rotate(5deg);
        transform: translateX(calc(4px / 2)) rotate(5deg);
    }

    45% {
        -webkit-transform: translateX(calc(-4px / 2)) rotate(calc(-5deg / 1.8));
        transform: translateX(calc(-4px / 2)) rotate(calc(-5deg / 1.8));
    }

    60% {
        -webkit-transform: translateX(calc(4px / 3.3)) rotate(calc(5deg / 3));
        transform: translateX(calc(4px / 3.3)) rotate(calc(5deg / 3));
    }

    75% {
        -webkit-transform: translateX(calc(-4px / 5.5)) rotate(calc(-5deg / 5));
        transform: translateX(calc(-4px / 5.5)) rotate(calc(-5deg / 5));
    }
}