@import '../../components/glassTheme/Common.scss';

.cardWrapper {
  @extend .unselectable;
  width: 100%;
  height: 100%;
}

.validationMessage {
  @extend .glass-body;
  text-align: center;
}

.validationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 20px;
}

.createButton {
  @extend .glass-button-icon;
  width: 180px;
  margin-bottom: 5px;
}