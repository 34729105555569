@import '../../components/glassTheme/Common.scss';

.navbar-items {
    @extend .darker-glass;
    @extend .diffuse;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    padding: 0 7px 0 7px;
    width: 95%;
    height: 80px;
    border-radius: 50px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
}

.menu-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0;
    height: 70px;
    width: 70px;
}

.reloadContainer {
    border: 18px solid;
    border-color: transparent;
    background-color: transparent;
    border-radius: 50px;
    height: 70px;
    width: 70px;
    transition: transform 1s ease-in-out;
    cursor: pointer;
}

.reload {
    border-width: 5px;
    width: 100%;
    height: 100%;
    color: white;
}

.reloadContainer:hover .reload {
    color: black;
    transition: color 700ms linear;
}

.reloadContainer:hover {
    background-color: rgba(255, 255, 255, 0.9);
    transform: rotate(360deg);
    transition: background-color border-color 1000ms linear;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.subtitleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    gap: 5px;
}

.nav-title {
    @extend .base-font;
    @extend .glass-element-subtle;
    font-size: xx-large;
    overflow: hidden;
}

.nav-subtitle {
    @extend .base-font;
    @extend .glass-element-bold;
    font-size: small;
    overflow: hidden;
}

.nav-version {
    @extend .base-font;
    @extend .glass-element-subtle;
    font-size: x-small;
}

.env-local {
    @extend .badge;
    @extend .base-font;
    @extend .glass-element-bold;
    background-color: purple;
    font-size: x-small;
    font-weight: 600;
}

.env-dev {
    @extend .env-local;
    background-color: blue;
}

.env-test {
    @extend .env-local;
    background-color: green;
}

.env-prod {
    @extend .env-local;
    // background: radial-gradient(circle at center, #FFD700, #deb323);
    background-color: rgb(224, 0, 0);
}

.env-unknown {
    @extend .env-local;
    background-color: rgb(255, 0, 247);
}

.fa-react {
    margin-left: 0.2rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style-type: none;
    align-items: center;
    text-align: center;
    justify-content: end;
}

.nav-links {
    text-decoration: none;
    color: white;
    padding: 0.7rem 1rem;
    white-space: nowrap;
}

.nav-links i {
    padding-right: 10px;
}
.nav-links:hover {
    background: #bd0000;
    border-radius: 30px;
    transition: all 0.2s ease-in-out;
}

.fa-bars,
.fa-times {
    color: white;

}

.menu-icons {
    display: none;
}

@media screen and (max-width: 850px) {
    .navbar-items {
        z-index: 210;
    }

    .nav-menu {
        display: none;
        flex-direction: column;
        justify-content: flex-start;
        background: rgba(255, 255, 255, .25);
        box-shadow: 0 8px 32px rgba(31, 38, 135, .37);
        backdrop-filter: blur(40px);
        border-radius: 40px;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        z-index: -3;
        transition: all 0.3s ease-in-out;
    }

    .nav-links {
        display: block;
        widows: 100%;
        padding: 2rem 0;
        color: #222;
    }

    .nav-links:hover {
        background: rgba(255, 255, 255, 0.25);
        backdrop-filter: blur(20px);
    }

    .menu-icons {
        display: block;
    }

    .nav-links-mobile {
        padding: 0 1.5rem;
        display: block;
        text-align: center;
        font-size: 1.3rem;
        font-weight: bold;
        width: 80%;
        margin: auto;
    }

}

.nav-menu.active {
    display: flex;
}

.nav-links-mobile {
    padding: 1rem 1rem;
    white-space: nowrap;
    background-color: #fafafa;
    border-radius: 30px;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    color: #222;
}