
.search-box {
    display: flex;
    flex-direction: row;
    height: 60px;
    border-radius: 50px;
    padding: 10px;
    z-index: 10;
}

.search-input {
    border: none;
    background: none;
    width: 0;
    padding: 0;
    color: #fff;
    float: left;
    font-size: 16px;
    transition: .3s;
    line-height: 40px;
}

.search-input::placeholder {
    color: #dbc5b0;
}

.search-btn {
    color: white;
    float: right;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: .3s;
}

.search-icon {
    width: 20px;
    height: 20px;
    fill: grey;
}

.active-icon {
    fill: white;
    transition: .3s;
}

.active-icon:hover {
    scale: 1.3;
    transition: .3s;
}

.search-input:focus,
.search-input:not(:placeholder-shown) {
    width: 240px;
    padding: 0 6px;
}

.search-box:hover > .search-input {
    width: 240px;
    padding: 0 6px;
}

.search-btn:hover > .search-icon {
    fill: white;
}

.search-box:hover > .search-btn,
.search-input:focus + .search-btn,
.search-input:not(:placeholder-shown) + .search-btn {
    visibility: none;
}
